/*
 * @Author: zhangguoliang
 * @Date: 2022-08-05 10:25:41
 * @LastEditors: zhangguoliang
 * @LastEditTime: 2022-10-20 16:35:28
 */
import request from '@/utils/request';
import { BaseResponse } from '@/interface';

export type CuTemplate = {
  templateName: string;
  templateCode: string;
  templateDes: string;
  templateArea: string;
};

export type TemplateItem = CuTemplate & {
  index: number;
  status: string;
  sequenceNbr: string;
};

export type Params = {
  page: number;
  limit: number;
};

type PageRes = {
  total: number;
  list: TemplateItem[];
};

// 模板列表查询
export function getTemplates(params: Params): Promise<BaseResponse<PageRes>> {
  return request({
    url: `/file-analysis-resource/1/filesTemplate`,
    method: 'GET',
    params,
  });
}

// 添加模板
export function addTemplate(data: CuTemplate): Promise<BaseResponse<any>> {
  return request({
    url: '/file-analysis-resource/1/filesTemplate',
    method: 'POST',
    data,
  });
}

// 更改模板状态
export function templateStatus(
  data: Pick<TemplateItem, 'sequenceNbr' | 'status'>
): Promise<BaseResponse<any>> {
  return request({
    url: '/file-analysis-resource/1/filesTemplate/status',
    method: 'POST',
    data,
  });
}

// 模板配置列表
export function templateConfig(id: string): Promise<BaseResponse<any>> {
  return request({
    url: `/file-analysis-resource/1/fileSectionInstanceManage/fileInstanceManage?fileTemplateId=${id}`,
    method: 'GET',
  });
}

// 查询原始数据
export function metadataList(): Promise<BaseResponse<any>> {
  return request({
    url: `/file-analysis-resource/1/filesSectionMetadata/queryMetadataList`,
    method: 'GET',
  });
}

// 新增配置
export function addConfig(data: any): Promise<BaseResponse<any>> {
  return request({
    url: `/file-analysis-resource/1/fileSectionInstanceManage`,
    method: 'POST',
    data,
  });
}

// 查询单条数据配置
export function getEntity(id: string): Promise<BaseResponse<any>> {
  return request({
    url: `/file-analysis-resource/1/fileSectionInstanceManage/entityId/${id}`,
    method: 'GET',
  });
}
