

















































import { RuleObject } from '@/interface';
import { isEmpty } from 'lodash';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { CuType } from './interface';
import { addTemplate, CuTemplate } from '@/api/fileTemplate';

@Component
export default class Cu extends Vue {
  @Prop() visible!: boolean;
  @Prop() type!: CuType;
  @Prop() record!: CuTemplate;

  provinces = ['河北省'];
  selectedProvinces: string[] = [];

  formState: CuTemplate = {
    templateName: '',
    templateCode: '',
    templateDes: '',
    templateArea: '',
  };

  rules: Record<string, RuleObject[]> = {
    templateName: [
      { required: true, message: '请输入模板名称', trigger: 'change' },
    ],
    templateCode: [
      { required: true, message: '请输入项目CODE', trigger: 'change' },
    ],
    // templateArea: [
    //   { required: true, message: '请至少选择一个省份', trigger: 'change' },
    // ],
  };

  get title(): string {
    return this.type === 'create' ? '新增模板' : '编辑模板';
  }

  @Watch('record', { immediate: true })
  watchRecord(record: CuTemplate): void {
    this.formState = {
      ...this.formState,
      ...record,
    };
    this.selectedProvinces = isEmpty(this.formState.templateArea)
      ? []
      : this.formState.templateArea.split(',');
  }

  changeProvinces(keys: string[]): void {
    this.formState.templateArea = keys.join(',');
  }

  closeModal(): void {
    (this.$refs['form'] as HTMLFormElement).resetFields();
    this.$emit('update:visible', false);
  }

  submitForm(form: string): void {
    (this.$refs[form] as HTMLFormElement).validate((valid: boolean) => {
      if (!valid) {
        return false;
      }
      addTemplate(this.formState).then(() => {
        this.$message.success('操作成功');
        this.$emit('refresh');
      });
      this.closeModal();
    });
  }
}
